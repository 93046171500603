@import "burger";

.infopopup {
  width: $infopopup-width;
  box-shadow: 10px -10px 38px rgba(0, 0, 0, 0.3),
    10px 15px 12px rgba(0, 0, 0, 0.22);
  color: $darkgrey;
  position: absolute;
  background: $offwhite-transparent;
  bottom: $timeline-height;
  left: $toolbar-width;
  border: 3px solid $offwhite;
  border-radius: 1px;
  padding: 20px;
  box-sizing: border-box;
  font-size: $large;
  transition: opacity 0.5s ease 0.1s, z-index 0.1s ease 0s;
  opacity: 1;
  z-index: $overheader;

  &.hidden {
    transition: 0.5s ease;
    opacity: 0;
  }

  .side-menu-burg {
    position: absolute;
    right: 8px;
    top: 10px;
    &.light {
      &.is-active span:after,
      &.is-active span:before {
        background: black;
      }
    }
  }

  p {
    word-wrap: break-word;
  }

  &.dark {
    // background: $black-transparent;
    background: rgba(0, 0, 0, 0.8);
    color: white;
  }

  iframe {
    flex: 1;
    width: 100%;
    min-height: 400px;
  }

  @media (max-height: 1000px) {
    iframe {
      min-height: 230px;
    }
  }

  &.mobile {
    border: none;
    padding: 5vmin;
    .side-menu-burg {
      display: none;
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
  }

  .legend-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    h2 {
      display: flex;
      font-size: 12pt;
      letter-spacing: 2px;
      margin: 0;
    }
  }

  .legend-container {
    height: 100%;
    display: flex;
    flex-direction: row;

    .legend-item {
      display: flex;
      justify-content: center;
      align-items: center;
      &.one {
        flex: 1;
      }
      &.three {
        flex: 5;
      }
    }
  }

  .legend-section {
    height: 25px;
    display: flex;
    align-items: center;

    svg {
      width: 60px;
      float: left;
      display: inline-block;
    }

    .legend-labels {
      display: flex;
    }
  }
}
